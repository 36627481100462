@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?3738ar');
  src:  url('fonts/icomoon.eot?3738ar#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?3738ar') format('truetype'),
    url('fonts/icomoon.woff?3738ar') format('woff'),
    url('fonts/icomoon.svg?3738ar#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-scaner:before {
  content: "\e910";
}
.icon-chevron-left:before {
  content: "\e900";
}
.icon-chevron-right:before {
  content: "\e901";
}
.icon-chevron-up:before {
  content: "\e902";
}
.icon-trophy-outline:before {
  content: "\e903";
}
.icon-wallet-outline:before {
  content: "\e904";
}
.icon-lock-outline:before {
  content: "\e905";
}
.icon-dolar-sign:before {
  content: "\e906";
}
.icon-giftcard-outline:before {
  content: "\e907";
}
.icon-headset-mic-outline:before {
  content: "\e908";
}
.icon-quote-outline:before {
  content: "\e909";
}
.icon-youtube:before {
  content: "\e90a";
}
.icon-linkedin:before {
  content: "\e90b";
}
.icon-facebook:before {
  content: "\e90c";
}
.icon-bitcoin:before {
  content: "\e90d";
}
.icon-check:before {
  content: "\e90e";
}
.icon-chevron-down:before {
  content: "\e90f";
}
.icon-burger-menu:before {
  content: "\e9bd";
}
