@import "src/styles/variables";

.text {
  margin: 0;
  font-weight: 400;

  &.variant-title1 {
    font-size: 64px;
    line-height: 76px; // 1.2 ratio
  }

  &.variant-title2 {
    font-size: 48px;
    line-height: 58px; // 1.2 ratio
  }

  &.variant-subtitle1 {
    font-size: 36px;
    line-height: 43px; // 1.2 ratio
  }

  &.variant-subtitle2 {
    font-size: 30px;
    line-height: 36px; // 1.2 ratio
  }

  &.variant-content1 {
    font-size: 24px;
    line-height: 36px; // 1.5 ratio
  }

  &.variant-content2 {
    font-size: 20px;
    line-height: 30px; // 1.5 ratio
  }

  &.variant-caption1 {
    font-size: 18px;
    line-height: 27px; // 1.5 ratio
  }

  &.variant-caption2 {
    font-size: 16px;
    line-height: 24px; // 1.5 ratio
  }

  &.weight-500 {
    font-weight: 500;
  }

  &.weight-600 {
    font-weight: 600;
  }

  &.weight-700 {
    font-weight: 700;
  }

  &.opacity {
    opacity: 0.6;
  }

  @mixin max-lines($lines) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
  }

  &.max-lines-1 {
    @include max-lines(1);
  }

  &.max-lines-2 {
    @include max-lines(2);
  }

  &.max-lines-3 {
    @include max-lines(3);
  }

  &.max-lines-4 {
    @include max-lines(4);
  }

  &.max-lines-5 {
    @include max-lines(5);
  }

  &.color-primary {
    color: $app-second-color;
  }

  &.color-secondary {
    color: $app-main-color;
  }

  &.color-tertiary {
    color: $text-tertiary-color;
  }

  &.color-error {
    color: $text-error-color;
  }

  &.color-success {
    color: $text-success-color;
  }

  &.align-left {
    text-align: left;
  }

  &.align-center {
    text-align: center;
  }

  &.align-right {
    text-align: right;
  }

  &.as-link {
    cursor: pointer;
    &:hover {
      color: $app-main-color;
    }
  }
}