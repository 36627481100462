.carousel {
  overflow: hidden;

  .carousel-inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 0 1 100%;
    gap: 20px;
    overflow: auto;

    & > div:first-child {
      margin-left: 20px;
    }

    & > div:last-child {
      margin-right: 20px;
    }

    .carousel-card  {
      width: 350px;
      max-width: 350px;
      min-width: 350px;
      border-radius: 10px;
    }
  }
}