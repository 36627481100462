@import "src/styles/variables";

.radio-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 15px;
  border: 2px solid $app-main-color;
  background: $app-second-color;
  max-width: 100%;

  @media(max-width: 600px) {
    gap: 10px;
  }

  &.size-sm {
    width: 120px;
    padding: 6px 8px;

    input {
      width: 16px;
      height: 16px;
      flex: 0 0 16px;
    }
  }

  &.size-md {
    width: 240px;
    padding: 8px 16px;

    input {
      width: 24px;
      height: 24px;
      flex: 0 0 24px;
    }
  }

  &.size-lg {
    width: 360px;
    padding: 10px 24px;

    input {
      width: 32px;
      height: 32px;
      flex: 0 0 32px;
    }

    @media(max-width: 600px) {
      padding: 10px 16px;
    }
  }

  &:hover {
    p {
      font-weight: 600;
    }
  }

  &.checked {
    background: $app-main-color;

    p {
      color: $app-second-color;
      font-weight: 600;
    }
  }

  p {
    text-transform: uppercase;
  }

  /* radio button style */
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid $app-main-color;
    background-color: $app-second-color;
    border-radius: 50%;
    margin: 0;

    &:checked {
      border: 8px solid $app-second-color;
      background-color: $app-main-color;
    }
  }
}