@import "src/styles/variables";

.transactions-table {
  /* ROW STYLE */
  .t-table-row {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;

    .t-table-cell {
      flex: 1;
    }
  }

  /* HEADER STYLE */
  .t-table-header {
    background: #210B71;
    padding: 20px;
  }

  /* BODY STYLE */
  .t-table-body {
    .t-table-row {
      padding: 10px 20px;

      &:first-child {
        padding-top: 20px;
      }
      &:last-child {
        padding-bottom: 20px;
      }
    }
  }

  /* MOBILE / TABLET */
  @media(min-width: 901px) {
    .t-table-row {
      .cell-toggle-row {
        display: none;
      }
    }
  }

  @media(max-width: 900px) {
    .t-table-body {
      .t-table-row {
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        padding: 20px;
      }
    }

    .t-table-row {
      .cell-date, .cell-status, .cell-card-type, .cell-card-holder {
        display: none;
      }

      .cell-id p, .cell-amount p {
        font-size: 20px;
      }

      .cell-toggle-row {
        display: flex;
        justify-content: flex-end;
        text-align: center;
        flex: 0 1 30px;

        .toggle-row {
          width: 24px;
          height: 24px;
          background: $app-main-color;
          border-radius: 50%;
          cursor: pointer;

          span {
            line-height: 24px;
          }
        }
      }
    }
  }

  @media(max-width: 600px) {
    .t-table-row {
      .cell-card-number {
        display: none;
      }
    }
  }
}