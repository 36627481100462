@import "src/styles/variables";

.crypto-card {
  img {
    max-width: 100%;
    width: 100%;
  }

  .card-content {
    text-align: left;
    padding: 15px 20px;
    background: $app-second-color;
    min-height: 250px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}