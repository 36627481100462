@font-face {
  font-family: "Rubik";
  font-weight: 400;
  src: url("../assets/fonts/Rubik-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik";
  font-weight: 500;
  src: url("../assets/fonts/Rubik-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik";
  font-weight: 600;
  src: url("../assets/fonts/Rubik-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik";
  font-weight: 700;
  src: url("../assets/fonts/Rubik-Bold.ttf") format("truetype");
}