@import "src/styles/variables";

.dropdown {
  position: relative;
  box-sizing: border-box;
  border-radius: 40px;
  border: 2px solid $app-main-color;
  background: rgba(22, 14, 51, 0.50);
  max-width: 100%;

  &.size-sm {
    width: 120px;
    padding: 12px 8px;
  }

  &.size-md {
    width: 240px;
    padding: 12px 16px;
  }

  &.size-lg {
    width: 360px;
    padding: 18px 24px;
  }

  .dropdown-value {
    text-transform: uppercase;
    max-width: 100%;

    svg {
      height: 24px;
    }
  }

  .dropdown-options {
    position: absolute;
    left: 0;
    list-style-type: none;
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 10px;
    border: 2px solid $app-main-color;
    background: rgba(22, 14, 51);
    padding: 0;
    margin: 0;
    max-width: 100%;

    &.size-sm {
      width: 120px;
      top: 52px; // dropdown height
    }

    &.size-md {
      width: 240px;
      top: 52px; // dropdown height
    }

    &.size-lg {
      width: 360px;
      top: 64px; // dropdown height
    }

    li {
      cursor: pointer;
      padding: 8px 24px;

      &:hover {
        p {
          color: $app-main-color;
        }
      }
    }
  }

  &.light-mode {
    background: $app-second-color;
    span.icon {
      color: $app-main-color;
    }

    .dropdown-options {
      background: $app-second-color;

      li {
        &:hover {
          p {
            font-weight: 700;
          }
        }
      }
    }
  }
}
