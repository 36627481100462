.mini-crypto-icons {
  gap: 30px;

  .crypto-icon {
    flex: 1 1 calc(25% - 50px);
  }

  @media(max-width: 700px) {
    gap: 10px;
    width: 60%;

    .crypto-icon {
      justify-content: center;
      flex: 1 1 calc(50% - 30px);
    }
  }
}