@import "src/styles/variables";

.upload-kyc-document {
  .upload-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;
    height: 260px;
    border-radius: 25px;
    border: 3px dashed $app-main-color;
    background: $app-second-color;
    overflow: hidden;
  }

  .upload-button {
    border: 2px solid $app-main-color;
    border-radius: 64px;
    width: 360px;
    max-width: 100%;
    padding: 13px 30px;
    box-sizing: border-box;

    p {
      text-transform: uppercase;
    }

    input {
      display: none;
    }
  }
}