@import "src/styles/_variables.scss";

input.input-field {
  box-sizing: border-box;
  background: rgba(22, 14, 51, 0.50);
  text-align: center;
  border-radius: 40px;
  border: 2px solid $app-main-color;
  color: $app-second-color;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  max-width: 100%;

  &.size-sm {
    width: 120px;
    padding: 12px 8px;
  }

  &.size-md {
    width: 240px;
    padding: 12px 16px;
  }

  &.size-lg {
    width: 360px;
    padding: 18px 24px;
  }

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: $app-second-color;
  }

  &.light-mode {
    background: $app-second-color;
    color: $app-main-color;


    &::placeholder {
      color: $app-main-color;
    }
  }
}