section.buy-crypto {
  .form-container {
    width: 60%;
    margin: 0 auto;
  }

  @media(max-width: 900px) {
    .form-container {
      width: 75%;
    }
  }

  @media(max-width: 600px) {
    .form-container {
      width: 90%;
    }
  }
}