@import "src/styles/variables";

.display-block {
  display: block;
}

.background-second-color {
  background: $app-main-color;
  color: $app-second-color;
}

.color-purple {
  color: $app-main-color;
}

.color-white {
  color: $app-second-color;
}

a {
  text-decoration: none;

  &:hover {
    p, svg {
      color: $app-main-color !important;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.main-background-image {
  width: 100%;
  background-image: url("../assets/bg.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
}