.crypto-trend {
  .form-content {
    width: 100%;
    max-width: 100%;

    .currency-dropdown {
      width: 40%;
      max-width: 120px;
    }

    .amount-input {
      width: 60%;
      max-width: 360px;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
}