section.credit-to-crypto {
  padding: 40px 0;

  & > img.coin {
    &.img-top-left {
      top: 25%;
      left: 10%;
    }

    &.img-top-right {
      top: 30%;
      right: 5%;
    }

    &.img-bottom-left {
      bottom: -12%;
      left: 3%;
    }

    &.img-bottom-right {
      bottom: 10%;
      right: 5%;
    }
  }

  .section-title {
    width: 60%;
  }

  .timer-container {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 64px;
    padding: 15px 30px;
    width: 360px;
    max-width: 90%;
    box-sizing: border-box;
    text-align: center;
  }

  button {
    max-width: 90%;
  }

  .crypto-icons {
    margin-top: 100px;
  }

  @media(max-width: 900px) {
    & > img.coin {
      &.img-top-left {
        top: 5%;
        left: -2%;
      }

      &.img-top-right {
        top: 30%;
        right: -3%;
      }

      &.img-bottom-left {
        bottom: -15%;
        left: -5%;
      }

      &.img-bottom-right {
        bottom: 10%;
        right: 2%;
      }
    }
  }
}