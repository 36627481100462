@import "src/styles/variables";

.burger-menu {
  position: relative;

  ul.burger-menu-list {
    position: absolute;
    list-style-type: none;
    overflow: hidden;
    width: 240px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 2px solid $app-main-color;
    background: rgba(22, 14, 51);
    top: 20px;
    right: 0;
    padding: 0;

    li {
      padding: 5px 24px;
      cursor: pointer;

      &:hover {
        a, p {
          color: $app-main-color;
        }
      }
    }
  }
}