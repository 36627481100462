section.knowledge {
  & > img.coin {
    &.img-bottom-left {
      bottom: -20%;
      left: -15%;
    }
  }

  @media(max-width: 1200px) {
    & > img.coin {
      &.img-bottom-left {
        bottom: -10%;
        left: 0%;
      }
    }
  }
}