@import "src/styles/variables";

.footer-sections {
  padding-bottom: 85px;
  border-bottom: 1px solid $app-second-color;

  section {
    &.crypto {
      flex: 1 1 calc(20% - 30px);
    }

    &.links {
      flex: 1 1 calc(20% - 30px);
    }

    &.legal {
      flex: 1 1 calc(20% - 30px);
    }

    &.newsletter {
      flex: 1 1 calc(40% - 30px);

      .newsletter-form {
        position: relative;
        max-width: 360px;

        input {
          width: 100%;
          text-align: left;
          text-transform: none;
          font-weight: 400;
          color: $text-tertiary-color;
          border-color: $app-second-color;

          &::placeholder {
            color: #E0E0E0;
          }
        }

        button {
          position: absolute;
          right: 10px;
          top: 10px;

          p {
            font-weight: 500;
          }
        }
      }
    }
  }

  @media(max-width: 900px) {
    section {
      &.crypto {
        flex: 1 1 100%;
      }

      &.links {
        flex: 1 1 calc(50% - 30px);
      }

      &.legal {
        flex: 1 1 calc(50% - 30px);
      }

      &.newsletter {
        flex: 1 1 100%;
      }
    }
  }
}