.socials {
  .privacy-and-terms {
    flex: 1 1 calc(40% - 20px);
    justify-content: flex-start;
  }

  .copy-right {
    flex: 1 1 calc(30% - 20px);
    justify-content: center;
  }

  .social-networks {
    flex: 1 1 calc(30% - 20px);
    justify-content: flex-end;
  }

  @media(max-width: 900px) {
    .privacy-and-terms,
    .copy-right,
    .social-networks {
      flex: 1 1 100%;
      justify-content: center;
    }
  }
}