@import "src/styles/variables";

.icon-container {
  .color-primary {
    color: $app-second-color;
  }

  .color-secondary {
    color: $app-main-color;
  }

  .color-tertiary {
    color: #160E33;
  }
}