section.testimonials {
  & > img.coin {
    &.img-top-left {
      top: -10%;
      left: -10%;
    }

    &.img-top-right {
      top: 15%;
      right: -5%;
    }
  }

  @media(max-width: 900px) {
    & > img.coin {
      &.img-top-left {
        top: -10%;
        left: -10%;
        height: 100px;
      }

      &.img-top-right {
        top: -10%;
        right: -10%;
      }
    }
  }
}