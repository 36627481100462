footer.footer {
  margin: 80px auto 100px;
  width: 70%;

  @media(max-width: 1200px) {
    width: 80%;
  }

  @media(max-width: 900px) {
    width: 90%;
  }
}