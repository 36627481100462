@import 'src/styles/variables';

nav.header {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background: #210B71;
  padding: 32px 0;
  width: 100%;
  height: $header-height;
  box-sizing: border-box;

  .nav-content {
    width: 80%;
    margin: auto;
  }

  @media(max-width: 1200px) {
    .nav-content {
      width: 90%;
    }
  }

  @media(min-width: 901px) {
    .burger-menu {
      display: none;
    }
  }

  @media(max-width: 900px) {
    .burger-menu {
      display: block;
    }

    .menu-items, .menu-settings {
      display: none;
    }
  }
}