.article-card {
  flex: 1 1 calc(50% - 20px);
  border-radius: 10px;

  .article-image {
    width: 160px;
    object-fit: contain;
  }

  @media(max-width: 900px) {
    flex: 1 1 100%;

    .article-image {
      width: 120px;
      object-fit: contain;
    }
  }
}