.display-flex {
  display: flex;
}

.display-flex-column {
  display: flex;
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

.gap-30 {
  gap: 30px;
}

