@import "./styles/variables";

/**
 * Load external libs
 */
// icons
@import './assets/icomoon-v1.0/style.css';
.icon {
  // default icon size
  font-size: 20px;
}

html {
  background: $app-bg-color;
  scroll-behavior: smooth;

  /* Remove scrollbar space */
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0;
  }

  body {
    font-family: "Rubik", sans-serif;

    .App {
      .application-content {
        margin-top: $header-height;
        min-height: calc(100vh - 661px);
      }
    }
  }
}