.feature-card {
  flex: 1 1 calc(33% - 20px);
  border-radius: 10px;

  @media(max-width: 1200px) {
    flex: 1 1 calc(50% - 20px);
  }

  @media(max-width: 900px) {
    flex: 1 1 100%;
  }
}