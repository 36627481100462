.kyc-flow {
  width: 100%;

  .kyc-flow-container {
    margin: 0 auto;
    width: 70%;
    max-width: 800px;
    padding: 60px 80px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 25px;

    .kyc-flow-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
    }
  }

  @media(max-width: 1200px) {
    .kyc-flow-container {
      width: 90%;
      padding: 30px;
    }
  }

  @media(max-width: 600px) {
    .kyc-flow-container {
      width: 90%;
      padding: 20px;
    }
  }
}