@import "src/styles/variables";

.testimonial-card {
  display: flex;
  height: 450px;
  padding: 0 20px;
  box-sizing: border-box;
  justify-content: center;
  border: 2px solid $app-main-color;

  .avatar {
    height: 48px;
    border-radius: 50%;
  }
}