@import "src/styles/variables";

button.custom-btn {
  text-align: center;
  border-radius: 64px;
  border-style: none;
  cursor: pointer;
  max-width: 100%;

  &.sm-btn {
    padding: 8px 15px;
  }

  &.lg-btn {
    width: 360px;
    padding: 13px 30px;
  }

  &.variant-filled {
    background: $app-main-color;
    border: 2px solid $app-main-color;
    p {
      color: $app-second-color;
    }
  }

  &.variant-outlined {
    background: $app-second-color;
    border: 2px solid $app-main-color;
    p {
      color: $app-main-color;
    }
  }

  &:not([disabled]):active {
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  }

  &:disabled {
    background: rgba(118, 118, 118, 0.3);
    border: 2px solid rgba(118, 118, 118, 0.3);
    color: rgba(255, 255, 255, 0.3);
    cursor: auto;
  }
}