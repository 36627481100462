section.attractive-features {
  & > img.coin {
    &.img-top-left {
      top: 5%;
      left: -10%;
    }

    &.img-top-right {
      top: -25%;
      right: -20%;
    }
  }

  @media(max-width: 1200px) {
    & > img.coin {
      &.img-top-left {
        top: 5%;
        left: -5%;
      }

      &.img-top-right {
        top: -10%;
        right: -10%;
      }
    }
  }

  @media(max-width: 900px) {
    & > img.coin {
      &.img-top-left, &.img-top-right {
        display: none;
      }
    }
  }
}